import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { EventService } from "../event.service";
import { BehaviorSubject, Subject, of } from "rxjs";
import { UserService } from "@core/app/services/user.service";
import { switchMap, first, filter, tap } from "rxjs/operators";
import { GTMService } from "@core/app/services/gtm.service";

@Component({
	selector: "cm-catering-service",
	template: `
		<h3 class="text-center mb-4">Select Your Service Style</h3>
		<ng-container *ngIf="event.serviceStyles$ | async as serviceStyles; else loading">
			<cm-slider
				[fullWidth]="true"
				[fullHeight]="true"
				[pagination]="false"
				[slideIndex]="slideIndex"
				[slidesInView]="slidesInView"
				class="text-center"
			>
				<ng-template cmSlide *ngFor="let serv of serviceStyles; index as i">
					<div class="h-100 d-flex flex-column px-3">
						<div class="embed-responsive embed-responsive-4by3">
							<div class="embed-responsive-item">
								<img [src]="images[serv.food_serv_typeid - 1] | image" class="img-fluid" />
							</div>
						</div>
						<div>
							<button
								*ngIf="event.eventInfo$ | async as eventInfo"
								type="button"
								class="btn btn-primary"
								[disabled]="
									(savingBS | async) ||
									!eventInfo ||
									eventInfo!.food_serv_typeid === serv.food_serv_typeid
								"
								(click)="save(serv.food_serv_typeid, serv.food_serv_type)"
							>
								{{ eventInfo!.food_serv_typeid === serv.food_serv_typeid ? "Selected" : "Select" }}
							</button>
						</div>
						<div class="my-3 flex-grow-1">{{ serv.food_serv_type_desc }}</div>
					</div>
				</ng-template>
			</cm-slider>
		</ng-container>

		<cm-login-modal
			[show]="showLoginBS | async"
			(showChange)="showLoginBS.next(false)"
			(loggedIn)="showLoginBS.next(false)"
		></cm-login-modal>

		<ng-template #loading>
			<ngb-progressbar type="primary" [value]="100" [striped]="true" [animated]="true" class="m-5">
				Loading...
			</ngb-progressbar>
		</ng-template>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CateringServiceComponent {
	@Output() saved = new EventEmitter<void>();

	showLoginBS = new BehaviorSubject(false);
	savingBS = new BehaviorSubject(false);
	slideIndex = 0;
	slidesInView = { xs: 1, sm: 2, md: 3, lg: 4, xl: 5 };

	constructor(
		public event: EventService,
		private toastr: ToastrService,
		private user: UserService,
		private gtmService: GTMService,
	) {}

	images = [
		"static/18/service-pickup.jpg",
		"static/18/service-buffet.jpg",
		"static/18/service-plated.jpg",
		"static/18/service-family.jpg",
		"static/18/service-cocktail.jpg",
	];

	save(food_serv_typeid: number, food_serv_type: string) {
		this.savingBS.next(true);

		this.user.loggedIn$
			.pipe(
				first(),
				switchMap((loggedIn) => {
					if (loggedIn) {
						return of(true);
					} else {
						this.showLoginBS.next(true);
						return this.showLoginBS.pipe(switchMap(() => this.user.loggedIn$));
					}
				}),
				first(),
				tap((x) => !x && this.savingBS.next(false)),
				filter((x) => x),
				switchMap(() => this.event.update({ food_serv_typeid })),
			)
			.subscribe(
				() => {
					this.gtmService.track("Food Service Type Set", "success", food_serv_type);
					this.saved.next();
					this.savingBS.next(false);
				},
				() => {
					this.savingBS.next(false);
					this.toastr.error("Failed to save event");
				},
			);
	}
}
