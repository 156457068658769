import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from "@angular/core";
import { UserService } from "@core/app/services/user.service";
import { BehaviorSubject } from "rxjs";
import { CateringMenuService } from "../catering-menu.service";
import { EventService } from "../event.service";

const blacklist = new Map([[1, new Set([5])]]);

@Component({
	selector: "cm-catering-menu-items",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="row" *ngIf="menu.itemsByCat$ | async as itemsByCat">
			<ng-container *ngFor="let catpair of itemsByCat | keyval; index as i">
				<ng-container *ngIf="cats.includes(catpair.key)">
					<h3 *ngIf="!subitems; else subhead" class="col-12">{{ catpair.key }}</h3>
					<ng-template #subhead>
						<h4 class="col-12">{{ catpair.key }}</h4>
					</ng-template>
					<h6 class="col-12">{{ (menu.allCatDescs$ | async)?.get(catpair.key) }}</h6>
					<ng-container *ngFor="let item of catpair.val">
						<div
							*ngIf="
								(event.eventInfo$ | async) &&
								!blacklist.get((event.eventInfo$ | async).food_serv_typeid)?.has(item.offerid)
							"
							class="col-lg-4 col-md-6 col-sm-12 px-sm-4 px-2 pb-3 pb-sm-4"
						>
							<div class="embed-responsive embed-responsive-16by9 border-heavy shadow">
								<div class="embed-responsive-item flex-center">
									<img
										[src]="item.image || 'static/18/generic-menu.jpg' | image : 'l'"
										[alt]="item.offer"
										class="img-fluid"
									/>
								</div>
							</div>
							<b class="item-name d-block mt-3">
								{{ item.offer }}
							</b>
							<div [innerHTML]="item.offer_desc"></div>
						</div>
					</ng-container>
					<div class="col-12" *ngFor="let cat of (menu.catTree$ | async)?.get(catpair.key)?.children">
						<cm-catering-menu-items [cats]="[cat.offer_cat]" [subitems]="true"></cm-catering-menu-items>
					</div>
				</ng-container>
			</ng-container>
		</div>
		<cm-catering-menu-item-modal [(offerid)]="showDetail" (saved)="saved.next()"></cm-catering-menu-item-modal>
	`,
	styles: [
		`
			:host {
				display: block;
			}
			h3 {
				line-height: 1;
			}
			@media (max-width: 500px) {
				h3 {
					font-size: 2.2em;
				}
			}
			.item-name {
				font-size: 1.5rem;
			}
			.price {
				font-size: 1.25rem;
			}
			.servings {
				letter-spacing: 2px;
			}
			.form-control {
				width: 4rem;
			}
		`,
	],
})
export class CateringMenuItemsComponent {
	@Input() cats!: string[];
	@Input() subitems: boolean = false;

	@Output() saved = new EventEmitter();

	blacklist = blacklist;
	showDetail: any | null = null;
	qty = 1;
	notes = "";
	register = false;
	savingBS = new BehaviorSubject(false);

	constructor(public menu: CateringMenuService, public user: UserService, public event: EventService) {}
}
