import { HttpClient } from "@angular/common/http";
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	EventEmitter,
} from "@angular/core";
import { UserService } from "@core/app/services/user.service";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, ReplaySubject, combineLatest } from "rxjs";
import { first, map, switchMap, tap, filter } from "rxjs/operators";
import { iter, tuple } from "shared/common";
import { EventService } from "../event.service";

@Component({
	selector: "cm-catering-menu-item-modal",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<cm-login-modal
			[show]="!(user.loggedIn$ | async) && offerid !== null"
			(showChange)="offeridChange.next(null)"
		></cm-login-modal>
		<cm-modal [show]="(user.loggedIn$ | async) && offerid !== null" (showChange)="offeridChange.next(null)">
			<ng-template cmModal>
				<ng-container *ngIf="!(loadingBS | async)">
					<div class="row">
						<div class="embed-responsive embed-responsive-16by9">
							<div class="embed-responsive-item flex-center">
								<img
									[src]="offerid.image || 'static/18/generic-menu.jpg' | image"
									class="w-100"
									[alt]="offerid.offer"
								/>
							</div>
						</div>
					</div>
					<div class="row py-sm-5 py-3 px-1">
						<div class="col-lg-6 col-12 pl-sm-5 pl-3 d-flex flex-column">
							<h3>{{ offerid.offer }}</h3>
							<div class="mb-3" [innerHTML]="offerid.offer_desc"></div>
							<small *ngIf="offerid.for_qty > 0" class="text-uppercase text-muted servings">
								{{ offerid.for_qty }} servings
							</small>
							<b class="item-name d-block mt-3">How many do you want?</b>
							<small class="text-uppercase text-muted servings d-flex align-items-center">
								Quantity:
								<input
									type="number"
									class="form-control form-control-sm"
									min="0"
									step="1"
									[ngModel]="offerid.qty"
									(ngModelChange)="setQty($event)"
								/>
							</small>
						</div>
						<div class="col-12 col-lg-6 pr-sm-5 pr-3 d-flex flex-column">
							<div *ngIf="suboffers$ | async as suboffers">
								<ng-container *ngIf="suboffers.length > 0">
									<h4 class="mb-0">Options</h4>
									<div *ngFor="let item of suboffers" class="d-flex my-1 align-items-center">
										<div>
											<div class="input-group flex-nowrap">
												<div class="input-group-prepend">
													<button
														class="btn btn-sm btn-outline-secondary"
														type="button"
														(click)="fill(item.offerid)"
													>
														Fill
													</button>
												</div>
												<input
													min="0"
													step="1"
													type="number"
													id="offer-{{ item.offerid }}"
													class="form-control form-control-sm"
													[disabled]="event.locked$ | async"
													[ngModel]="activeItems.get(item.offerid) || 0"
													(ngModelChange)="setActiveItem(item.offerid, $event)"
												/>
											</div>
										</div>
										<div class="pl-2">
											<label for="offer-{{ item.offerid }}" class="form-check-label">
												{{ item.offer }}
												<span *ngIf="item.offer_price !== 0">
													{{ item.offer_price | currency : undefined : undefined : "1.2-2" }}
												</span>
											</label>
										</div>
									</div>
								</ng-container>
							</div>
							<div class="flex-grow-1"></div>
							<div>
								<b class="item-name d-block mt-3">Notes</b>
								<small class="d-flex align-items-center">
									Let us know how we can customize this item for you!
								</small>
								<textarea
									class="form-control form-control-sm w-100"
									[(ngModel)]="offerid.notes"
								></textarea>
							</div>
						</div>
						<div class="col-12 col-lg-6">
							<button
								*ngIf="!offerid.order_itemid"
								type="button"
								class="btn btn-primary w-100 mt-3"
								[disabled]="savingBS | async"
								(click)="addToEvent(offerid)"
							>
								Add to Event
							</button>
							<button
								*ngIf="offerid.order_itemid"
								type="button"
								class="btn btn-primary w-100 mt-3"
								[disabled]="savingBS | async"
								(click)="addToEvent(offerid)"
							>
								Update
							</button>
						</div>
					</div>
				</ng-container>
			</ng-template>
		</cm-modal>
	`,
	styles: [
		`
			h3 {
				line-height: 1;
			}
			@media (max-width: 500px) {
				h3 {
					font-size: 2.2em;
				}
			}
			.item-name {
				font-size: 1.5rem;
			}
			.servings {
				letter-spacing: 2px;
			}
			.form-control {
				width: 4rem;
			}
		`,
	],
})
export class CateringMenuItemModalComponent implements OnChanges {
	@Input() offerid: any = null;

	@Output() offeridChange = new EventEmitter();
	@Output() saved = new EventEmitter();

	offeridRS = new ReplaySubject<any>(1);

	savingBS = new BehaviorSubject(false);
	loadingBS = new BehaviorSubject(true);

	activeItems = new Map<number, number>();
	suboffers$ = combineLatest(this.offeridRS, this.event.eventInfo$).pipe(
		filter(([_, eventInfo]) => !!eventInfo),
		switchMap(([offer, eventInfo]) =>
			this.http.post("api/statement/GetSuboffers", {
				vars: { pkg_offerid: offer.offerid, orderid: eventInfo!.orderid },
			}),
		),
		map((res: any) => res.results),
		tap((res) => {
			this.activeItems = iter(res)
				.map((row: any) => tuple(row.offerid, row.qty || 0))
				.toMap();
		}),
	);

	constructor(
		public user: UserService,
		private event: EventService,
		private toastrService: ToastrService,
		private http: HttpClient,
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.offerid && changes.offerid.currentValue !== null) {
			this.offeridRS.next(this.offerid);
			this.event.eventItemMap$
				.pipe(
					map((x) => x.get(this.offerid.offerid)),
					first(),
				)
				.subscribe((item) => {
					if (item) {
						this.offerid.qty = item.qty;
						this.offerid.notes = item.notes;
						this.offerid.order_itemid = item.order_itemid;
					}
					this.loadingBS.next(false);
				});
		}
	}

	addToEvent(offerid: any) {
		this.savingBS.next(true);
		if (
			offerid.qty > 0 &&
			(!this.activeItems.size ||
				iter(this.activeItems)
					.map(([_, item]) => item)
					.sum() === offerid.qty)
		) {
			this.event.addItem(offerid.offerid, this.activeItems, offerid.qty, offerid.notes).subscribe(
				() => {
					this.savingBS.next(false);
					this.saved.next();
					this.offerid = null;
				},
				() => this.savingBS.next(false),
			);
		} else if (offerid.qty < 1) {
			this.toastrService.error("Qty Must Be Greater Than 0", "Invalid Qty Provided");
			this.savingBS.next(false);
		} else if (
			this.activeItems.size &&
			iter(this.activeItems)
				.map(([_, item]) => item)
				.sum() !== offerid.qty
		) {
			this.toastrService.error("Qty Of Options Must Match Overall Qty", "Invalid Qty Provided");
			this.savingBS.next(false);
		}
	}

	setQty(qty: number) {
		this.offerid.qty = qty;
		this.suboffers$.pipe(first()).subscribe((suboffers) => this.fill(suboffers[0].offerid));
	}

	setActiveItem(offerid: number, qty: number) {
		this.activeItems.set(offerid, qty);
	}

	fill(offerid: number) {
		const otherTotal = iter(this.activeItems)
			.filter(([k, _]) => k !== offerid)
			.map(([_, v]) => v)
			.sum();
		this.activeItems.set(offerid, this.offerid.qty - otherTotal);
	}
}
