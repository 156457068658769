import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	dealerInfo: {
		name: "lakeshore-rv",
		homeBreadcrumb: "RV Dealer",
		themeData: {
			dealerAccent: {
				bg: "#3c5b97",
				bgHover: "#4366af",
				fg: "#FFF",
				fgHover: "#FFF",
				base: { bg: "#333", fg: "#FFF", light: "#aaa" },
				offset: "#fff",
				highlight: {
					bg: "#e82535",
					bgHover: "#ff2029",
					fg: "#FFF",
				},
				homeUrl: "https://dfm-cdn.com/static/1/homepage-background.jpg",
				footerUrl: "static/1/footer-img-s.png",
			},
		},
	},
};
